/*
 * Third party
 */

@import '../libs/reset-css/_reset';
@import '_fonts';
@import '_variables';

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('fonts/helveticaneuecyr-medium-webfont.eot');
//   src: url('fonts/helveticaneuecyr-medium-webfont.eot?#iefix') format("embedded-opentype"), url('fonts/helveticaneuecyr-medium-webfont.woff2') format("woff2"), url('fonts/helveticaneuecyr-medium-webfont.woff') format("woff"), url('fonts/helveticaneuecyr-medium-webfont.ttf') format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

html {
  height: 100%;
}

header, nav, section, article, .sidebar, footer {
  display: block;
}

body {
  font-family: 'Helvetica Neue', Tahoma, Arial, sans-serif;
  font-size: 14px;
  /*background: url(../img/Bottom_texture.jpg);*/
  color: #333;
  min-height: 100%;
}

.header {
  height: 150px;
  width: 1024px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
}

.header__adress {
  width: 50%;
  text-align: center;
  color: #e56629;
  padding-bottom: 45px;
  h1 {
    font-size: 24px;
  }
}

.header__tel {
  width: 40%;
  text-align: center;
  color: #48aba8;
  font-size: 16px;
  padding-bottom: 45px;
  a {
    color: #48aba8;
    font-size: 22px;
    text-decoration: none;
    padding-right: 15px;
  }
}

h2 {
  color: #333;
  font-size: 20px;
}
h3{
  color: #e56629;
  font-size: 18px;
}

span {
  font-weight: bold;
  margin-left: 20px;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  width: 100%;
}

nav {
  width: 820px;
  height: 40px;
  background: #238494;
  position: absolute;
  top: 111px;
  right: 0;
  margin-right: -6px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 16px;
  ul {
    float: left;
    position: relative;
    left: 50%;
  }
  li {
    line-height: 40px;
    float: left;
    list-style: none;
    position: relative;
    right: 50%;
    a {
      display: block;
      color: #fff;
      padding: 0 12px;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        color: #fff;
        background: #E56629;
        transition: all 0.3s ease;
      }
    }
    .active {
      color: #fff;
      background: #E56629;
    }
  }
}

.container {
  width: 1024px;
  min-height: 46%;
  background-color: white;
  margin-top: 28px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  z-index: -1;
  margin: 0 auto 22px;
  border-top-left-radius: 5px;
  padding: 5px
  table {
    margin-bottom: 40px;
  }
}

.header__logo {
  padding-left: 20px;
}

.zamok{
  width: 600px;
  padding-left: 110px;
  margin-top: -5px;
}

aside.sale h3 {
  padding-left: 5px;
  margin-bottom: 15px;
}
.sale {
  padding-left: 10px;
  color: #fff;
  padding-bottom: 10px;
  img {
    border-radius: 5px;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  span{
    // color: #e56629;
    margin-left: 0;
  }
}

.sidebar {
  width: 200px;
  padding-bottom: 20000px;
  margin-bottom: -20000px;
  float: left;
  background-color: #48ABA8;
  color: #008A68;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  h3 {
    padding-left: 15px;
    line-height: 34px;
    color: #fff;
  }
  li {
    z-index: 100;
  }
}

menu {
  color: #008A68;
  margin-bottom: 32px;
  li {
    background: #238494;
    border: 1px solid #FFFFFF;
    list-style: none;
    width: 250px;
    margin-left: -1px;
    &:active {
      background: #E56629;
    }
    a {
      text-decoration: none;
      display: block;
      padding: 5px 5px 5px 15px;
      color: #fff;
    }
    ul {
      display: none;
      li:hover {
        color: #e1e1e1;
        transition: all 0.3s ease;
      }
    }
    &:hover {
      position: relative;
      background: #E56629;
      color: #e1e1e1;
      transition: all 0.3s ease;
      > ul {
        display: block;
      }
      ul {
        position: absolute;
        top: -1px;
        left: 199px;
        li {
          background: #E56629;
        }
      }
    }
    ul li {
      &:hover, &:active {
        background: #238494;
      }
    }
  }
}

main {
  background-color: #fff;
  float: left;
  padding-bottom: 20000px;
  margin-bottom: -20000px;
  width: 823px;
  p {
    margin: 10px 20px;
    text-align: justify;
  }
}

.category {
  border: 1px solid #e1e1e1;
  width:790px;
  padding-left: 8px;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left:15px;
  h3 {
    margin: 15px 10px 10px;
  }
  .first {
    margin-bottom: 0;
  }
  .more {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    margin-top: -30px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    background: rgba(229, 102, 41, 0.9);
    text-align: center;
    font-weight: 400;
    color: #fff;
    border-radius: 5px;
    display: none;
  }
  .category__item {
    position: relative;
    width: 182px;
    display: inline-block;
    padding: 5px;
    text-align: center;
    margin-bottom: 10px;
    vertical-align: middle;
    &:hover {
      img {
        opacity: 0.5;
        transition: all 0.5s ease
      }
      .more{
        display: block;
      }
      a {
        color: #238494;
      }
      color: #e1e1e1;
    }
    a {
      color: #333;
      text-decoration: none;
    }
    img {
      width: 183px;
      margin-bottom: 15px;
      border-radius: 5px;
    }
  }
}

#owl-head {
  padding-top: 1%;
  width: 98%;
  margin: 0 auto -2px;
  padding-left: 7px;
  z-index: 10;
  .owl-wrapper-outer {
    border-radius: 10px;
  }
  .owl-controls {
    margin-top: -30px;
    .active{
      opacity: 0.5;
    }
    span {
      border: 2px solid rgb(35, 132, 148);
      opacity: 0.9;
      width: 10px;
      height: 10px;
      background: #E56629;
    }
  }
}

/*
.sidebar .banner1{
  width: 200px;
  height: 165px;
  display: block;
  background: url(../images/banner/price.jpg) 50% 50% no-repeat;
  background-size: 95%;
  margin: 5px auto;
}
.sidebar .banner2{
  width: 200px;
  height: 165px;
  display: block;
  background: url(../images/banner/price-exel.jpg) 50% 50% no-repeat;
  background-size: 95%;
  margin: 5px auto;
}
.sidebar h2{
  text-align: center;
	font: 600 18px Tahoma;
	line-height: 35px;
}*/
.content a{
  line-height: 20px;
  font-size: 14px;
  text-decoration: none;
  color: #008A68;
  margin-left: 18px;
}

.content {
  margin: 0 30px;
  h2{
    margin-top: 10px;
  }
  h3 {
    margin: 10px;
  }
  .content__item {
    width: 100%;
    margin-top: 10px;
    position: relative;
  }
    img {
      vertical-align: middle;
      width: 180px;
    }
  p {
    line-height: 20px;
    font-size: 14px;
    text-decoration: none;
    color: #e56629;
    font-weight: bold;
    margin-left: 18px;
  }
  span {
    line-height: 18px;
    font-size: 14px;
    text-decoration: none;
    color: #000;
    font-weight: normal;
    margin-left: 18px;
  }
  .kod {
    position: absolute;
    top: 40%;
    color: rgb(72, 171, 168);
    font-weight: 700;
  }
  a {
    color: rgb(72, 171, 168);
    &:hover {
      color: #e56629;
    }
  }
}

.content__line {
  display: block;
  height: 150px;
}

figure {
  margin: 10px 0 30px 0;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  float: left;
  width: 100px;
}

.content figure {
  &:first-child {
    margin-left: 22px;
    margin-left: none;
  }
  + figure {
    margin-left: 28px;
  }
}

figure figcaption {
  position: absolute;
  top: 40px;
  font-size: 15px;
  color: #206988;
  font-weight: bold;
  text-align: center;
}

figcaption {
  margin-top: 0;
}

footer {
  clear: both;
  margin: -23px auto 10px;
  width: 1024px;
  height: 50px;
  background: #685245;
  border: 1px solid rgb(225, 225, 225);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0 10px;
  z-index: 2;
  p {
    width: 400px;
    display: block;
    margin: 0 auto;
    padding-top: 15px;
    color: #fff;
    text-align: center;
  }
}

.T {
  margin-left: 30px;
}

.F2, .F3 {
  margin-left: 50px;
}

input[type="submit"] {
  margin-left: 180px;
}

#toTop {
  width: 42px;
  height: 42px;
  background:#48aba8;
  text-align:center;
  line-height: 45px;
  border-radius: 50%;
  padding:10px;
  position: fixed;
  bottom:12px;
  right:16px;
  cursor:pointer;
  display:none;
  color:#fff;
  font-size: 30px;
  webkit-box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
  box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
}

#price {
  width: 42px;
  height: 42px;
  background:#48aba8;
  text-align:center;
  border-radius: 50%;
  padding:10px;
  position: fixed;
  bottom: 55%;
  right: 16px;
  cursor:pointer;
  color:#fff;
  font-family:verdana;
  font-size:11px;
  webkit-box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
  box-shadow: 0 4px 5px -1px rgba(0,0,0,0.5);
  a {
    font-size: 12px;
    line-height: 42px;
    text-transform: uppercase;
    color:#fff;
    text-decoration: none;
  }
}

@import '_media';
